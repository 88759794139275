<template>
  <div>
    <v-text-field :value="datesFormatted"
                  label="Select dates"
                  readonly
                  @click="datePickerDates=dates; showDatePicker=true"
                  append-icon="mdi-calendar"
                  clearable
                  v-on:click:clear="$emit('update:dates',[])"
    />
    <v-dialog
      v-model="showDatePicker"
      ref="dialog"
      width="290px"
      persistent
    >
      <v-date-picker
        v-model="datePickerDates"
        :range="isRange"
        no-title
        @close="closeDatePicker"
        @update:picker-date="!isRange && closeDatePicker()"
      ></v-date-picker>
      <v-btn @click="closeDatePicker">Close</v-btn>
    </v-dialog>

  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'DatePicker',
  props: {
    dates: {default: null, required: false},
    date: {default: null, required: false},
  },
  methods: {
    closeDatePicker() {
      this.showDatePicker = false;
      if (this.isRange) {
        this.$emit('update:dates', this.datePickerDates.sort());
      } else {
        this.$emit('update:date', this.datePickerDates);
      }
    }
  },
  computed: {
    isRange() {
      return this.dates !== null;
    },
    dateFrom() {
      const dates = [...(this.dates || [])].sort();
      return dates[0];
    },
    dateTo() {
      const dates = [...(this.dates || [])].sort().reverse();
      return dates[0];
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.isRange) {
        return this.date ? moment(this.date).format(format) : '';
      } else {
        if (!this.dateFrom) {
          return '';
        } else if (this.dateFrom === this.dateTo) {
          return moment(this.dateFrom).format(format);
        } else {
          return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
        }
      }
    },
  },
  data() {
    return {
      datePickerDates: null,
      showDatePicker: false,
    }
  }
}
</script>
<style>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
