<style>
.filter-input {
  max-width: 400px;
}

.filter-number {
  max-width: 120px;
}
</style>
<template>
  <v-card flat>
    <v-toolbar flat>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search (meal name or meal id number)"
        single-line
        hide-details
        clearable
      />
      <v-spacer/>
      <date-picker
        :date.sync="fromDate"
      />
      <date-picker
        :date.sync="toDate"
      />
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-select
                label="Load a Filter"
                v-model="filterName"
                :items="availableFilters"
                item-value="id"
                item-text="id"
                @change="applyFilter(filterName)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Filter Name"
                v-model="filterName"
                :rules="[v => /^[a-z0-9]+$/i.test(v) || 'must not contain spaces or special characters']"
              />
              <v-text-field
                label="Filter Description (displayed to customer)"
                v-model="filterDescription"
              />
              <v-toolbar flat>
                <v-btn class="ml-2" @click="saveFilter">
                  Save Filter
                </v-btn>
                <v-spacer/>
                <v-btn @click="newFilter">
                  New/Clear
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="">
          <v-row>
            <v-col>
              <v-card-text v-if="restrictions">
                <v-autocomplete
                  label="Restrictions"
                  v-model="restriction"
                  :items="Object.values(restrictions).flatMap(Object.values)"
                  item-text="text"
                  item-value="id"
                  multiple
                  chips
                  deletable-chips
                />
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-toolbar flat>Nutrient Targets
                <v-spacer/>
                <v-btn @click="targets.push(createTargetData())">Add Nutrient Target</v-btn>
              </v-toolbar>
              <v-toolbar flat>
                <v-checkbox label="Daily Target (based on 3 meals)"
                            v-model="isDailyTarget"
                />
                <v-spacer/>
                <v-checkbox label="Only Tracked Nutrients"
                            v-model="onlyTracked"
                />
              </v-toolbar>
                <v-list>
                  <v-list-item v-for="(target,i) of targets" v-bind:key="i">
                    <v-list-item-action>
                      <v-btn @click="targets.splice(i,1)"
                             icon>
                        <v-icon>mdi-trash-can</v-icon>
                      </v-btn>
                    </v-list-item-action>
                    <v-list-item-content v-if="nutrients">
                      <!--                {{ target.nutrient_id }}-->
                      <v-autocomplete
                        class="filter-input"
                        v-model="target.nutrient_id"
                        :items="nutrientsFiltered"
                        item-text="nutrientname"
                        item-value="nutrientnameid"
                      />
                      <template v-if="target.nutrient_id">
                        <v-text-field
                          v-model="target.lower_limit" class="filter-number pl-4" :suffix="getUnit(target)"
                          label="min" type="number"
                          clearable
                        />
                        <v-text-field
                          v-model="target.upper_limit" class="filter-number pl-4" :suffix="getUnit(target)"
                          label="max" type="number"
                          clearable
                        />
                        <v-text-field
                          v-model="target.target" class="filter-number pl-4" :suffix="getUnit(target)"
                          label="target" type="number"
                          clearable
                        />
                      </template>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
    </v-card-text>
    <template v-if="isCheckStale">
      <v-card-text>
        <v-alert outlined type="warning">The results below need to be checked again</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="check">Check Meals</v-btn>
      </v-card-actions>
    </template>
    <template v-if="!isCheckStale">
      <v-card-title>
      <span v-if="showCriteria==='all'">
        {{ filteredMeals.length }} meals found
      </span>
        <span v-if="showCriteria!=='all'">
        {{ filteredMeals.length }} of {{ meals.length }} {{
            showCriteria
          }} meals ({{ ((filteredMeals.length / meals.length) * 100).toFixed(1) }}%)
      </span>

      </v-card-title>
      <v-card-actions>
        <v-radio-group label="Showing Criteria" row v-model="showCriteria">
          <v-radio value="all" label="All">All</v-radio>
          <v-radio value="problem" label="Problem"/>
          <v-radio value="valid" label="Valid"/>
        </v-radio-group>
      </v-card-actions>
      <v-card-text>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="filteredMeals"
          sort-by="date"
          :loading="loading || checking"
          no-data-text="select a date range"
          disable-pagination
          hide-default-footer
          dense
        >
          <template v-slot:item.sku="{ item }">
            <v-btn icon shaped :class="{ yellow : item.is_selected}" class="mr-2" disabled>
              <v-icon>mdi-star</v-icon>
            </v-btn>
            <MealSkuLink :sku="item.sku"/>

          </template>
          <template v-for="(t,i) of targets"
                    v-slot:[`item.targets.${t.nutrient_id}`]="{ item }">
            <div v-bind:key="i" v-if="item.targets[t.nutrient_id]">
              {{ item.targets[t.nutrient_id].amount.toFixed(2) }} {{ item.targets[t.nutrient_id].unit }}
              <v-chip-group v-if="item.targets[t.nutrient_id].problem">
                <v-chip outlined color="warning" v-for="(v,k) of item.targets[t.nutrient_id].problem" v-bind:key="k">
                  {{ k }}: {{ v.toFixed(2) }}
                </v-chip>
              </v-chip-group>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </template>
  </v-card>
</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {dateFormatted, deepCopy} from '@/store/utils';
import api from '@/api';
import MealSkuLink from "@/components/MealSkuLink";
import urlState from "@/router/urlState";
import DatePicker from "@/components/DatePicker";

export default {
  components: {DatePicker, MealSkuLink},
  mixins: [urlState],
  data() {
    return {
      readOnly: true,
      loading: false,
      checking: false,
      loadingPromise: undefined,
      mealMap: {},
      meals: [],
      defaultHeaders: [
        {text: 'Meal Id', value: 'id', width: 100},
        {text: 'SKU', value: 'sku', width: 180},
        {text: 'name', value: 'name'},
        {text: 'diet', value: 'diet'},
        {text: 'date', value: 'date'},
      ],
      search: null,
      showImages: true,
      needPlatingOrPackingImage: null,
      fromDate: null,
      toDate: null,
      targets: [],
      lastTargetSearch: null,
      isDailyTarget: true,
      showCriteria: 'all',
      restriction: [],
      targetHeaders: [],
      restrictionHeaders: [],
      filterName: null,
      availableFilters: [],
      filterDescription: '',
      onlyTracked: true
    }
  },
  async created() {
    Promise.all([
      this.fetchDiets(),
      this.fetchNutrients(),
      this.fetchRestrictions()
    ])
      .then(() => {
        this.fetchData();
      });
    this.readOnly = !!this.$route.meta.readOnly;
    this.syncToUrl({
      param: 'isDailyTarget', urlParam: 'daily', initFromRoute: true,
      parseCallback: (v) => v === 'true'
    })
    this.syncToUrl({
      param: 'showCriteria', urlParam: 'show', initFromRoute: true,
    })
    this.syncToUrl({
      param: 'filterName', urlParam: 'filter', initFromRoute: true,
      // parseCallback: (v) => Number(v)
    });
    this.syncToUrl({
      param: 'search', urlParam: 'search', initFromRoute: true,
      // parseCallback: (v) => Number(v)
    });
    this.syncToUrl({
      param: 'fromDate', urlParam: 'from', initFromRoute: true,
      // parseCallback: (v) => Number(v)
    });
    this.syncToUrl({
      param: 'toDate', urlParam: 'to', initFromRoute: true,
      // parseCallback: (v) => Number(v)
    });
    this.syncToUrl({
      param: 'restriction', urlParam: 'restriction', initFromRoute: true,
      parseCallback: v => v.map ? v : [v]
    });

    function parseTarget(s) {
      console.log('parse', s);
      let [nutrient_id, lower_limit, upper_limit, target] = s.split(',').map(v => v && Number(v));
      return {nutrient_id, lower_limit, upper_limit, target}

    }

    function transformTarget(t) {
      return [t.nutrient_id, t.lower_limit, t.upper_limit, t.target].join(',');
    }

    this.syncToUrl({
      param: 'targets', urlParam: 'targets', initFromRoute: true, paramWatchOptions: {deep: true},
      transformCallback: v => v.map ? v.map(transformTarget) : transformTarget(v),
      parseCallback: v => v.map ? v.map(parseTarget) : [parseTarget(v)]
    })

    await this.fetchFilters();
    if (this.filterName) {
      this.applyFilter(this.filterName)
    }
  },
  watch: {
    fromDate() {
      this.fetchData()
    },
    toDate() {
      this.fetchData()
    },
  },
  computed: {
    ...mapState(['nutrients', 'restrictions']),
    ...mapGetters(['getDietName']),
    headers() {
      return [...this.defaultHeaders, ...this.targetHeaders, ...this.restrictionHeaders]
    },
    filteredMeals() {
      if (this.isCheckStale) {
        return [];
      }
      let meals = this.meals;
      if (this.showCriteria) {
        const hasProblem = m => m.hasProblem && (m.hasProblem.restrictions || m.hasProblem.targets);
        if (this.showCriteria === 'problem') {
          meals = meals.filter(hasProblem)
        }
        if (this.showCriteria === 'valid') {
          meals = meals.filter(m => !hasProblem(m))
        }
      }
      return meals;
    },
    isCheckStale() {
      // console.log('is stale', this.lastTargetSearch);
      // console.log('is stale', this.serialiseCheck());
      return this.lastTargetSearch !== this.serialiseCheck();
    },
    nutrientsFiltered() {
      return Object
        .values(this.nutrients)
        .filter(n => !this.onlyTracked || n.tracked)
    }
  },
  methods: {
    ...mapActions(['fetchDiets', 'fetchNutrients', 'fetchRestrictions']),
    fetchData(from = 0) {
      if (this.loading) {
        return false;
      }

      if (from === 0) {
        this.meals = [];
        this.mealMap = {};
        this.loading = true;
      }
      if (!(this.fromDate && this.toDate)) {
        console.log('no date range selected');
        this.loading = false;
        return;
      }

      function f(d) {
        return `${d}T00:00:00.000Z`
      }

      const where = {
        date: {
          gte: f(this.fromDate),
          lte: f(this.toDate),
        }
      };
      // console.log('fetching', {from});
      return api
        .get(`/v2/meal`, {
          params: {
            from,
            // limit,
            // sortBy,
            // sortDesc,
            orderBy: {date: 'asc'},
            where,
            minimal: true
          }
        })
        .then(({data}) => {
          const {count, meals} = data;
          console.log('got data', {count, meals});
          // console.log('first', JSON.stringify(meals[0]));
          // console.log('last', JSON.stringify(meals[meals.length - 1]));

          meals.forEach(meal => {
            const existing = this.mealMap[meal.id];
            if (existing) {
              console.log('dupe', {existing, meal})
            } else {
              this.mealMap[meal.id] = meal;
              if (Object.keys(meal.images || {}).length === 0) {
                delete meal.images;
                meal.noImages = true;
              }
              meal.diet = this.getDietName(meal.diet);
              meal.targets = {};
              meal.restriction = {};
              meal.hasProblem = {};
              this.meals.push(meal);
            }
          })
          if (meals.length > 0 && this.meals.length < count) {
            return this.fetchData(from + meals.length);
          }
        })
        .then(() => {
          this.check();
        })
        .finally(() => {
          this.loading = false;

        })
    },
    dateFormatted,
    getUnit(target) {
      const nutrient = target && this.nutrients[target.nutrient_id];
      return target && nutrient && nutrient.unit;
    },
    createTargetData() {
      return {
        nutrient_id: '',
        upper_limit: '',
        lower_limit: '',
        target: ''
      }
    },
    serialiseCheck() {
      return JSON.stringify({targets: this.targets, restriction: this.restriction});
    },
    check() {
      this.checking = true;
      const promises = [];
      if (this.targets.length) {
        promises.push(this.checkTargets())
      }
      if (this.restriction) {
        promises.push(this.checkRestrictions())
      }
      this.restrictionHeaders = this.restriction.map(id => ({
        value: `restriction.${id}`, text: this.restrictions[id].text
      }))
      this.targetHeaders = this.targets.map(t => ({
        value: `targets.${t.nutrient_id}`,
        text: this.nutrients[t.nutrient_id] ? this.nutrients[t.nutrient_id].nutrientname : t.nutrient_id
      }))

      this.lastTargetSearch = this.serialiseCheck();
      // console.log('check setting',this.lastTargetSearch)
      return Promise.all(promises).finally(() => this.checking = false);
    },
    async checkRestrictions() {
      for (const meal of this.meals) {
        await api
          .post(`v2/restrictions`, {mealIds: [meal.id], restrictionIds: this.restriction})
          .then(r => {
            // console.log('result', r.data);
            this.$set(meal.hasProblem, 'restrictions', Object.keys(r.data).length > 0);
            Object.keys(r.data)
              .forEach(foodId => {
                Object.keys(r.data[foodId])
                  .forEach(restrictionId => {
                    const restrictions = meal.restriction[restrictionId] || [];
                    restrictions.push(foodId);
                    this.$set(meal.restriction, restrictionId, restrictions);
                  })
              })
            // r.data.forEach(result => {
            //   this.$set(meal.targets, result.target.nutrient_id, result);
            //   if (result.problem) {
            //     meal.hasProblem = true;
            //   }
            // })
            // console.log('meal after', meal)
          });
      }
    },
    async checkTargets() {

      function scaleTargets(targets) {
        return targets.map(t => {
          const result = {nutrient_id: t.nutrient_id};
          for (const k of ['upper_limit', 'lower_limit', 'target']) {
            if (t[k]) {
              result[k] = t[k] * 3
            }
          }
          return result;
        })
      }

      const targets = this.isDailyTarget ? this.targets : scaleTargets(this.targets);
      for (const meal of this.meals) {
        await api
          .post(`meals/nutrients`, {mealIds: [meal.id], targets})
          .then(r => {
            // console.log('result', r.data);
            this.$set(meal.hasProblem, 'targets', false);
            r.data.forEach(result => {
              this.$set(meal.targets, result.target.nutrient_id, result);
              if (result.problem) {
                this.$set(meal.hasProblem, 'targets', true);
              }
            })
            // console.log('meal after', meal)
          });
      }
    },
    async fetchFilters() {
      await api.get('/v2/meal-filter')
        .then(({data}) => this.availableFilters = data);
      console.log('fetched filters', this.availableFilters);
    },
    saveFilter() {
      const {filterName, targets, restriction, filterDescription} = this;
      api.put(`v2/meal-filter/${filterName}`, {
        nutrient_targets: targets,
        restrictions: restriction,
        description: filterDescription
      });
      this.fetchFilters();
    },
    applyFilter(filterName) {
      const filter = this.availableFilters.find(({id}) => id === filterName);
      if (filter) {
        this.restriction = deepCopy(filter.restrictions);
        this.targets = deepCopy(filter.nutrient_targets);
        this.filterDescription = filter.description;
      } else {
        console.warn('could not find filter', filterName, this.availableFilters);
      }
    },
    newFilter() {
      this.filterName = '';
      this.restriction = [];
      this.targets = []
      this.filterDescription = ''
    }
  }
}
</script>
